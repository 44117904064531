<template>
  <div >
    <el-row>
      <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handleControllerAdd">
        {{$t('Add')+$t('Controller')}}
      </el-button>
      <div style="float: right;">
          <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
                  <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
          </el-select>
      </div>
    </el-row>
    <el-table
      :header-cell-style="{color:'#20638C'}"
      v-loading="listLoading"
      :data="list" style="width: 100%;"
      :cell-style="{padding: '0', height: '40px'}"
      ref="controllerTable"
      highlight-current-row
      @row-click="handleRowClick"
     >
      <el-table-column align="center" prop="id" v-bind:label="$t('Number')" sortable />
      <el-table-column align="center" prop="controllerName" v-bind:label="$t('Name')" sortable />
      <el-table-column align="center" prop="serviceInfo.name" :label="$t('Operator')" v-if="serviceList.length>0" />
      <el-table-column align="center" prop="stationId" v-bind:label="$t('ChargingStation')" :formatter="showStationName"/>
      <el-table-column align="center" prop="ip" label="IP" />
      <el-table-column align="center" prop="apiKey" label="Project Key" width="200px" />
      <el-table-column  align="center" prop="datetime" v-bind:label="$t('Create')+$t('Date')" :show-overflow-tooltip="true" >
        <template slot-scope="scope">
          {{ parseDateTime(scope.row.datetime) }}
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('Operate')" width="130px" >
        <template slot-scope="scope">
           <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showControllerSetting(scope.row)"/>
           <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" @click="handleControllerDelete(scope.$index, scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
    <ControllerIoTSetting :dialogControllerVisible="dialogControllerSettingVisible" :controllerData="controllerData" :serviceList="serviceList" @close="dialogControllerSettingOnClose()" />
    <Device :serviceId="serviceId" :controllerId="controllerId" :stationId="stationId" :returnData="returnData" />
    <TagIoT :serviceId="serviceId" :controllerId="controllerId" @return-text="getReturnData"/>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import ControllerIoTSetting from './ControllerIoTSetting.vue'
import Device from './Device.vue'
import TagIoT from './TagIoT.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import {apiUrl} from "@/utils/main";

export default {
  name: 'Meter',
  components: {
    Pagination,
    ControllerIoTSetting,
    Device,
    TagIoT
  },
  mixins: [mixins],
  data() {
    return {
      dialogWidth: 0,
      total: 0,
      list: [],
      serviceList: [],
      listLoading: false,
      listQuery: {
        serviceId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
        sort: 'id,Desc'
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      serviceId: undefined,
      controllerId: undefined,
      stationId: undefined,
      controllerData: undefined, // 控制器資訊
      dialogControllerSettingVisible: false,
      returnData: 0,
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.serviceId': function () {
        this.listQuery.page = 0
        this.resetPage(this.getList)
        this.serviceId = this.listQuery.serviceId
    }
  },
  async created() {
    this.dialogWidth = this.setDialogWidth()
    this.resetPage(this.getList)
    this.getServiceOptions();
    //refreshToken();
  },
  beforeDestroy() {
    //stopRefreshToken();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    showStationName(row,column,cellValue) {
        return (this.$store.state.chargingStations || []).find((item) => item.id === cellValue).name;
    },
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/meteriot', {params: this.listQuery}).then(res=> {
        this.list = res.data.content
        this.total = res.data.totalElements
        if (this.total>0) {
            this.serviceId = this.list[0].serviceInfo.id
            this.controllerId = this.list[0].id
            this.stationId = this.list[0].stationId
            if (this.controllerId==undefined) //預設 抓取第一筆控制器並反白
                this.$refs.controllerTable.setCurrentRow(this.list[0])
            else {
                this.$refs.controllerTable.setCurrentRow((this.list||[]).find((item) => item.id === this.controllerId))
            }
        } else { // 清空device頁面
            this.controllerId = -1
        }
        this.listLoading = false
      })
    },
    getServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/enabled').then(res => {
            this.serviceList = res.data
        })
    },
    handleRowClick(row, column, event) {
        //console.log(row)
        this.serviceId = row.serviceInfo.id
        this.controllerId = row.id
        this.stationId = row.stationId
        this.$refs.controllerTable.setCurrentRow(row)
    },
    handleControllerAdd() {
      this.dialogControllerSettingVisible = true
    },
    showControllerSetting(row) {
      this.dialogControllerSettingVisible = true
      this.controllerData = row
    },
    handleControllerDelete(index, row) {
      const confirmText = ['確定刪除控制器 - ' + row.controllerName + ' ?','(請確認此控制器已無納管任何設備及監控點)']
        const newDatas = []
        const h = this.$createElement
        for (const i in confirmText) {
          newDatas.push(h('p', null, confirmText[i]))
      }
      this.$confirm('提示', {
        message: h('div', null, newDatas),
        //message: '確定刪除控制器 - ' + row.controllerName + ' ?',
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delController(index, row.id)
      }).catch(()=> {
        console.log('cancel delete station')
      })
    },
    async delController(index, id) {
      this.axios.delete('/api/v1/meteriot/' + id).then(()=> {
        this.$delete(this.list, index);
        this.$message({showClose: true,message: '刪除成功',type: 'success'})
      }).catch(err => {
        this.$message({message: '刪除失敗 '+err.data,type: 'error'})
      })
    },
    dialogControllerSettingOnClose() {
      this.dialogControllerSettingVisible = false
      this.controllerData = undefined
      this.resetPage(this.getList)
    },
    getReturnData(data) {
        this.returnData = this.returnData +1
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-table__body tr.current-row > td {
    background-color: LightYellow !important;
}
</style>
