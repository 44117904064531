<template>
    <el-dialog width="600" v-bind:title="$t('Tag')+' '+$t('Settings')" :visible="dialogTagVisible" @close="dialogTagOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small">
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="tagName" v-bind:label="$t('Name')">
                        <el-input id="tagName" size="small" v-model="updateQuery.tagName" :placeholder="$t('Input')+' '+$t('Name')" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="type" v-bind:label="$t('StandardTagName')">
                        <el-select id="type" v-model="updateQuery.type" filterable clearable :filter-method="dataFilter" @focus="selectFocus" size="small" class="filter-item">
                            <el-option
                                  v-for="item in this.type"
                                  :label="item.label"
                                  :key="item.value"
                                  :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="deviceName" v-bind:label="$t('Device')+$t('Name')">
                        <el-input id="deviceName" size="small" v-model="updateQuery.deviceName" :placeholder="$t('Input')+' '+$t('Name')" class="filter-item" clearable :disabled="this.isModify"/>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="orgPath" v-bind:label="$t('Device')+$t('Path')">
                        <el-input id="orgPath" size="small" v-model="updateQuery.orgPath" placeholder="e.g. CHT/TL/大安站" class="filter-item" clearable :disabled="this.isModify"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="iotDeviceId" v-bind:label="'IoT '+$t('Device')+' ID'">
                        <el-input id="iotDeviceId" size="small" v-model="updateQuery.iotDeviceId" placeholder="e.g. 123456789" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="iotSensorId" v-bind:label="'IoT '+$t('Sensor')+' ID'">
                        <el-input id="iotSensorId" size="small" v-model="updateQuery.iotSensorId" placeholder="e.g. power" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="unit" v-bind:label="$t('TagUnit')">
                        <el-input id="unit" size="small" v-model="updateQuery.unit" :placeholder="$t('Input')+' '+$t('TagUnit')" class="filter-item" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary" @click="updateTag()">{{$t('Save')}}</el-button>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";

export default {
    name: 'TagSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        tagData: {
            require: false,
            default: undefined,
            type: Object
        },
        listData: {
            require: false,
            default: undefined,
            type: Object
        },
        dialogTagVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            typeFilter: [{value:-1,label:'不指定'},{value:1,label:'用電度數'},{value:2,label:'平均需量'},{value:3,label:'有效功率'},{value:4,label:'功率因數'},
            {value:5,label:'無效功率'},{value:6,label:'R相電流(IR)'},{value:7,label:'R相電壓(VRS)'},{value:8,label:'S相電流(IS)'},{value:9,label:'S相電壓(VST)'},
            {value:10,label:'T相電流(IT)'},{value:11,label:'T相電壓(VTR)'},{value:12,label:'三相電流'},{value:13,label:'三相電壓'},{value:14,label:'視在功率'},
            {value:15,label:'交流電頻率'},{value:16,label:'R-S線的電壓值'},{value:17,label:'S-T線的電壓值'},{value:18,label:'T-R線的電壓值'},{value:19,label:'交流電線數'},
            {value:20,label:'A相電流角'},{value:21,label:'A相電壓角'},{value:22,label:'B相電流角'},{value:23,label:'B相電壓角'},{value:24,label:'C相電流角'},
            {value:25,label:'C相電壓角'},{value:26,label:'KVAH'},{value:27,label:'無效電度'},{value:28,label:'固定式預測需量'},{value:29,label:'滑動式預測需量'},
            {value:30,label:'同步式預測需量'},{value:31,label:'虛擬用電度數'},{value:32,label:'虛擬有效功率'},{value:33,label:'需量預測值'},{value:34,label:'電驛狀態'},
            {value:35,label:'北向通訊狀態'},{value:36,label:'南向通訊狀態'},{value:50,label:'讀卡機狀態'},
            {value:44001,label:'A相線電流Deadband'},{value:44002,label:'B相線電流Deadband'},{value:44003,label:'C相線電流Deadband'},{value:44004,label:'N相線電流Deadband'},
            {value:44005,label:'AB線電壓Deadband'},{value:44006,label:'BC線電壓Deadband'},{value:44007,label:'AC線電壓Deadband'},{value:44008,label:'實功Deadband'},
            {value:44009,label:'虛功Deadband'},{value:44010,label:'功率因數Deadband'},{value:44011,label:'頻率Deadband'},
            {value:80003,label:'直流電壓'},{value:80004,label:'直流電流'},{value:80005,label:'直流功率'},{value:80011,label:'轉換效率'},{value:80015,label:'單位面積用電量(kWH/M2)'},
            {value:120005,label:'亮度設定'},{value:130001,label:'二進位讀值'},{value:130002,label:'八進位讀值'},{value:130003,label:'十六進位讀值'},{value:130004,label:'功率(路燈)'},
            {value:130005,label:'電流(路燈)'},{value:130006,label:'電壓(路燈)'},{value:130007,label:'燈具亮度'},{value:99990000,label:'監控點錯誤碼'},
            {value:88860000,label:'實功控制'},{value:88870000,label:'功率因數控制'},{value:88880000,label:'自主調控'},{value:88890000,label:'虛功控制'},],
            type: undefined,
            isModify: false,
            updateQuery: {
                controllerId: undefined,
                id: undefined,
                deviceName: undefined,
                orgPath: undefined,
                tagName: undefined,
                type: undefined,
                iotDeviceId: undefined,
                iotSensorId: undefined,
                unit: undefined,
            },
            rules: {
                deviceName: [{ required: true, message: '(必填)', trigger: 'blur' }],
                orgPath: [{ required: true, message: '(必填)', trigger: 'blur' }],
                tagName: [{ required: true, message: '(必填)', trigger: 'blur' }],
                type: [{ required: true, message: '(必填)', trigger: 'blur' }],
                iotDeviceId: [{ required: true, message: '(必填)', trigger: 'blur' }],
                iotSensorId: [{ required: true, message: '(必填)', trigger: 'blur' }],
                unit: [{ required: true, message: '(必填)', trigger: 'blur' }],
            }
        };
    },
    watch: {
        tagData(val) {
            if (val) {
                this.isModify = true
                this.updateQuery.controllerId = val.controllerId
                this.updateQuery.id = val.id
                this.updateQuery.deviceName = val.deviceName
                this.updateQuery.orgPath = val.orgPath
                this.updateQuery.tagName = val.tagName
                this.updateQuery.type = val.type
                this.updateQuery.iotDeviceId = val.iotDeviceId
                this.updateQuery.iotSensorId = val.iotSensorId
                this.updateQuery.unit = val.unit
            }
        },
        listData(val) { // create tag
            if (val) {
                this.updateQuery.controllerId = val.controllerId
            }
        }
    },
    async created() {
        this.type = this.typeFilter
    },
    methods: {
        dataFilter(val) {
            if (val) {
                this.type = this.typeFilter.filter((item) => {
                    if (item.label.includes(val) || item.label.toUpperCase().includes(val.toUpperCase())) {
                        return true
                    }
                })
            } else
                this.type = this.typeFilter;
        },
        selectFocus(e){
            //console.log('selectFocus '+e.target.value)
            this.type = this.typeFilter;
        },
        dialogTagOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        updateTag() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.axios.put('/api/v1/tagiot', this.updateQuery).then(() => {
                        this.$message({
                            showClose: true,
                            message: this.$t('SuccessEdited'),
                            type: 'success'
                        })
                        this.dialogTagOnClose()
                    }).catch(err => {
                        this.$message({
                            message: this.$t('FailEdited')+err.data,
                            type: 'error'
                        })
                    })
                } else {
                    this.$message({
                        message: '輸入欄位格式不正確',
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.isModify = false
            this.updateQuery = {
                controllerId: undefined,
                id: undefined,
                deviceName: undefined,
                orgPath: undefined,
                tagName: undefined,
                type: undefined,
                iotDeviceId: undefined,
                iotSensorId: undefined,
                unit: undefined,
            }
        }
    }
}
</script>
<style>
/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
    .el-form-item__content {
        text-align: left;
    }
}
@media (min-width: 768px) {
    .el-input {
        width: 200px;
    }
    .el-select {
        width: 200px;
    }
    .el-form-item__content {
        text-align: left;
        margin-left: 120px;
    }
    .el-form-item__label {
        width: 120px;
    }
 }
</style>