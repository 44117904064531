<template>
  <div style="margin-top:10px">
  <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="7" style="padding: 7px;">
  <el-card class="box-card" shadow="never">
    <div slot="header" class="clearfix">
      <span class="role-span">{{$t('Tag')+' '+$t('List')}}</span>
      <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handleTagAdd">
        {{$t('Add')+$t('Tag')}}
      </el-button>
    </div>
    <el-table
      :header-cell-style="{color:'#20638C'}"
      v-loading="listLoading"
      :data="list" style="width: 100%;"
      :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
     >
      <el-table-column align="center" prop="id" v-bind:label="$t('Number')" />
      <el-table-column align="center" prop="tagName" v-bind:label="$t('Name')" />
      <el-table-column align="center" prop="iotDeviceId" v-bind:label="'IoT'+$t('Device')" />
      <el-table-column align="center" prop="iotSensorId" v-bind:label="'IoT'+$t('Sensor')" />
      <el-table-column align="center" :label="$t('Operate')" width="130px" >
        <template slot-scope="scope">
            <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showTagSetting(scope.row)"/>
            <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" @click="handleTagDelete(scope.$index, scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
    <TagIoTSetting :dialogTagVisible="dialogTagSettingVisible" :tagData="tagData" :listData="listData" @close="dialogTagSettingOnClose()" />
  </el-card>
  </el-col>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import TagIoTSetting from './TagIoTSetting.vue'

export default {
  name: 'Tag',
  components: {
    Pagination,
    TagIoTSetting,
  },
  mixins: [mixins],
  props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
      serviceId: {
          require: false,
          default: undefined,
          type: Number
      },
      controllerId: {
          require: false,
          default: undefined,
          type: Number
      }
  },
  data() {
    return {
      result: '',
      dialogWidth: 0,
      total: 0,
      list: [],
      listLoading: false,
      listData: undefined,
      listQuery: {
        serviceId: undefined,
        controllerId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
        sort: 'id,Desc'
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      tagData: undefined, // 監控點資訊
      dialogTagSettingVisible: false,
    };
  },
  watch: {
    serviceId(val) {
        if (val)
            this.listQuery.serviceId = val
    },
    controllerId(val) {
        if (val) {
            this.listQuery.controllerId = val
        }
    },
    'listQuery.controllerId': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    }
  },
  async created() {
    this.dialogWidth = this.setDialogWidth()
    this.resetPage(this.getList)
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getList() {
      if (this.listQuery.controllerId!=undefined) {
          this.listLoading = true
          this.axios.get('/api/v1/tagiot', {params: this.listQuery}).then(res=> {
            this.list = res.data.content
            this.total = res.data.totalElements
            this.listLoading = false
          })
          this.$emit('return-text', this.listQuery.controllerId);
      }
    },
    handleTagAdd() {
      this.dialogTagSettingVisible = true
      this.listData = this.listQuery //this.tagData = this.listQuery.controllerId
    },
    showTagSetting(row) {
      this.dialogTagSettingVisible = true
      this.tagData = row
    },
    handleTagDelete(index, row) {
      this.$confirm('提示', {
        message: '確定刪除監控點 - ' + row.tagName + ' ?',
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delTag(index, row.id)
      }).catch(()=> {
        console.log('cancel delete tag')
      })
    },
    async delTag(index, id) {
      this.axios.delete('/api/v1/tagiot/' + id).then(()=> {
        this.$delete(this.list, index);
        this.$message({showClose: true,message: '刪除成功',type: 'success'})
      }).catch(err => {
        this.$message({message: '刪除失敗 '+err.data,type: 'error'})
      })
    },
    dialogTagSettingOnClose() {
      this.dialogTagSettingVisible = false
      this.listData = undefined
      this.tagData = undefined
      this.resetPage(this.getList)
    },
  }
}
</script>
